import Layout from "components/layouts/Layout"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import UniversalLink from "components/common/ui/UniversalLink"
import UniversalImage from "components/common/ui/UniversalImage"
import * as styles from "./404.module.scss"
import Section from "components/common/ui/Section"
import SEO from "components/common/Seo"
import useCurrentLanguage from "src/hooks/useCurrentLanguage"
import { translate } from "src/utils/language"

const NotFoundPage = () => {
  const query = useStaticQuery(graphql`
    query {
      miscSettingsPage: wpSettingsPage(slug: { eq: "misc" }) {
        ..._404Fragment
        translations {
          ..._404Fragment
        }
      }
    }
  `)

  const locale = useCurrentLanguage()
  const miscSettingsPage = translate(query.miscSettingsPage, locale)

  const {
    title,
    sorry,
    toMainPage,
    image,
  } = miscSettingsPage.otherSettings.pageNotFound

  return (
    <Layout>
      <SEO useDefaultTitle />
      <Section>
        <Container className={styles.container}>
          <UniversalImage image={image} className={styles.image} />
          <h4 className={styles.title}>{title}</h4>
          <p>
            {sorry} <br /> <UniversalLink to="/">{toMainPage}</UniversalLink>
          </p>
        </Container>
      </Section>
    </Layout>
  )
}

export default NotFoundPage
