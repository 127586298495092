import React from "react"
import PropTypes from "prop-types"
import * as styles from "./Section.module.scss"
import classNames from "classnames"

const Section = ({ children, className, ...rest }) => (
  <section className={classNames(styles.section, className)} {...rest}>
    {children}
  </section>
)

Section.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
}

export default Section
